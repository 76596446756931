import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SectionInfo from '../components/SectionInfo';
import SEO from '../components/SEO';
import styled from 'styled-components';
import { Col, Container, Row, Section } from '../styles/common/Layout';
import { H1BoxBlack } from '../styles/common/Element';
import Layout from '../components/Layout';
import SectionDonation from '../components/SectionDonation';


const A = styled.a`
  font-size: 1.6rem;
  color: var(--black);

  span {
    font-weight: bold;
  }
`

const PressFiles = styled.div`
  margin-bottom: 3rem;
`

function PressePage({ data }) {
  const { settings } = data;
  const presse = data.presse.nodes;
  return (
    <Layout bgColor={'white'}>
    <>
      <SEO
        title="Presse"
        keywords={['Presse',`Kulturgesichter`, `NRW`, `Alarmstufe Rot`, `Alarmstufe`]}
        image={data.settings.image.asset.fixed}
      />
      <Section id="press">
        <Container>
          <Row>
            <Col>
              <H1BoxBlack>Pressematerial,Publikationen &amp; Positionierung</H1BoxBlack>
            </Col>
          </Row>
          <Row>
            <Col>
            <PressFiles>
            <h2>Pressemitteilungen</h2>
              {presse.map(press => (
                <p>Mitteilung vom <A key={press._id}
                href={`${press.presspdf.asset.url}?dl=${press.presspdf.asset.originalFilename.replace(/[\s/\\?%*:|"<>#]/g, '-').toLowerCase()}`}><span>{press.date}</span> - {press.presspdf.asset.originalFilename}</A></p>
              ) )}
              </PressFiles>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Das Bildmaterial darf für Presse - Veranstaltunszwecke verwendet werden.
                Bitte geben Sie den Urheber mit an.</p>
                <p>
                <strong>Kontakt Pressestelle</strong><br />
                Patrick Berg<br />
                presse@kulturgesichternrw.de</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <SectionDonation />
    </>
    </Layout>
  );
}

export default PressePage;

export const query = graphql`
  query PresseSettingsQuery {
    settings: sanityStoreSettings {
      name
      image {
        asset {
          fixed(width: 325) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    presse: allSanityPresse {
      nodes {
        presspdf {
          asset {
            path
            url
            originalFilename
          }
        }
        date
      }
    }
  }
`;