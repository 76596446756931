import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Col, Col_2, Container, Row } from '../styles/common/Layout.js';
import { H1Box } from '../styles/common/Element.js';

const ContainerMargin = styled(Container)`
  margin-bottom: 30px;
  padding-top: 30px;
`;

const RowMargin = styled(Row)`
  margin-bottom: 30px;
`;

export default function SectionInfo() {
  const data = useStaticQuery(graphql`
    query TeamQuery {
      team: sanityStoreSettings {
        name
        imageTeam {
          asset {
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <ContainerMargin as="section" id="infos">
        <Row>
          <Col>
            <H1Box>#OHNE UNS IST'S STILL</H1Box>
          </Col>
        </Row>
        <RowMargin>
          <Col>
            <p>
              <strong>
                WARUM KULTURGESICHTER NRW OHNE LOKALE ORTSKENNZAHL?
              </strong>
            </p>
            <p>
              Kulturgesichter NRW ist eine ehrenamtliche Initiative der
              Veranstaltungswirtschaft Nordwest. Nach dem Bremer Vorbild haben
              wir im September beschlossen auch in unserem Bundesland eine
              Kulturgesichtergruppe zu initiieren, so hat sich unser Orga-Team
              aus Freiwilligen mit Herkunft aus den verschiedenen
              Ruhrgebietsstädten und dem Niederrhein, u.a Kempen, Essen,
              Münster, Gelsenkirchen, Wuppertal, Köln &amp; Rheinberg zusammen
              gefunden um gemeinsam ehrenamtlich zu agieren.{' '}
            </p>
            <p>
              Uns erschien Kulturgesichter NRW zusammengefasst als folgerichtig
              und wir verstehen uns als Partner aller Kulturgesichter-Gruppen
              sowohl lokal NRW als auch bundesweit und sind gerne bereit diese
              zu unterstützen.
            </p>
            <p>
              Wir begrüßen die Entwicklung das sich inzwischen zahlreiche
              Kulturgesichter Gruppen auf lokaler Ebene gebildet haben und
              sicherlich weitere noch diesem Beispiel folgen werden. Sowohl die
              lokale als auch die landesweite Gruppierung wird dazu beitragen
              die Botschaft flächendeckend zu transportieren.
            </p>
          </Col>
          <Col>
            <p>
              Die Kulturgesichter sind die Dienstleister hinter den Kulissen,
              Shows, Konzerten, Festivals, Events, Messen und mehr. Hier zeigen
              wir euch unsere Gesichter, um auf die prekäre Lage und die Stille
              in der Veranstaltungsbranche aufmerksam zu machen.
            </p>
            <p>
              Die Veranstaltungsbranche – der Kollateralschaden von Covid 19.
              Für viele von uns bedeutet die Pandemie von heute auf morgen,
              keine Jobs, keine Aufträge und keine Perspektive. Keine
              Unterstützung von Seiten der Regierung.
            </p>
            <p>
              Wir möchten Aufmerksamkeit für unsere Branche und den sterbenden
              Existenzen ein Gesicht geben. Daher planen wir eine großangelegte
              Poster Aktion um auf die anhaltende, sehr prekäre Lage und die
              Stille in der Veranstaltungsbranche aufmerksam zu machen. Aus
              Solidarität haben sich verschiedene prominente Künstler dazu
              bereit erklärt, auch Ihre Gesichter für unser Projekt zur
              Verfügung zu stellen und geben gemeinsam mit uns der Kulturbranche
              ein Gesicht.
            </p>
          </Col>
        </RowMargin>
        <Row>
          <Col>
            <H1Box>WIR BENÖTIGEN EUREN SUPPORT</H1Box>
          </Col>
        </Row>
        <RowMargin>
          <Col>
            <p>
              Als ein Hilferuf der Veranstaltungsbranche planen und realisieren
              wir die Aktionen komplett ehrenamtlich mit dem einzigen Ziel,
              unsere Existenzen zu retten indem wir aufklären WER wir sind und
              WAS wir machen. Denn <strong>#Ohne uns ist es still!</strong>
            </p>
          </Col>
        </RowMargin>
        <Row>
          <Col>
            <H1Box>WER WIR ALLES SIND</H1Box>
            <p>
              Veranstalter // Eventagenturen // Managements // Light-Designer //
              Bühnenbauer // Bühnenausstatter // Rigger //
              Veranstaltungstechniker // Bühnenmeister // Caterer //
              Veranstaltungsausstatter // Musical-Darsteller // Merchandiser //
              Nightlinerfirmen // Busfahrer // Pyrotechniker // Schauspieler //
              Sänger // Theaterbetreiber // Festival-Veranstalter // Plakatierer
              // Ticketverkäufer // Clubbetreiber // Discothekenbetreiber //
              DJ‘s // Künstler // Werbeagenturen // Druckereien // Freelancer //
              Bühnenbildner // Regisseure // Fotografen // Schausteller //
              Musiker // Messebetreiber // Eventmanager // Eventmarketer //
              Eventplaner // Solo-Selbstständige // Grafiker // Hotelbetreiber
              // Ticketingfirmen // Busunternehmer // Mietwagen-Verleiher //
              Hallenbetreiber // Securitymitarbeiter // Stagemanager // Booker
              // Eventfotografen // Freiberufler // Garderobiere //
              Gastromitarbeiter // Kinobetreiber // Video-Operator und sehr
              viele mehr.
            </p>
          </Col>
        </Row>
      </ContainerMargin>
    </>
  );
}
